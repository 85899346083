const dizionario_documentazione = {
  routes: {
    home: {
      it: `Home`,
      en: `Home`,
      de: `Startseite`
    },
    send: {
      it: `Invio Dati`,
      en: `Send Data`,
      de: `Daten senden`
    },
    send_inventory: {
      it: `Invio Disponibilità`,
      en: `Send Inventory`,
      de: `Inventar senden`
    },
    retrieve: {
      it: `Lettura Dati`,
      en: `Retrieve Data`,
      de: `Daten abrufen`
    },
    trentino_marketing: {
      it: `Trentino Marketing`
    }
  },
  gruppi: {
    tipi: {
      struttura: {
        it: `Singola struttura (es. Hotel Belvedere)`
      },
      territorio: {
        it: `Territorio di vaste dimensioni (es. Trentino)`
      },
      zona: {
        it: `Zona all'interno di un territorio (es. Val di Fassa)`
      },
      località: {
        it: `Località all'interno di una zona (es. Canazei)`
      },
      concorrente: {
        it: `Gruppo di competitor creato da una struttura`
      },
      gruppo_personalizzato: {
        it: `Gruppo personalizzato creato dai manager di destinazione`
      }
    }
  },
  campi: {
    obbligatorio: {
      it: `obbligatorio`,
      en: `mandatory`
    },
    opzionale: {
      it: `opzionale`,
      en: `optional`
    },
    email: {
      it: `Email utente`,
      en: `User email`
    },
    password: {
      it: `Password utente`,
      en: `User password`
    },
    granularità: {
      it: `Granularità temporale dei dati`,
      en: `Granularity`
    },
    cornice_temporale: {
      it: `Limiti temporali dei dati`,
      en: `Time frame`
    },
    gruppi: {
      it: `Gruppi per cui recuperare i dati`,
      en: `Groups`
    },
    indicatori: {
      it: `Indicatori per cui recuperare i dati`,
      en: `Indicators`
    },
    id_prenotazione: {
      it: `ID univoco della prenotazione`,
      en: `Unique reservation ID`
    },
    data_creazione: {
      it: `Data della creazione della prenotazione`,
      en: `Date when the reservation was created`
    },
    data_arrivo: {
      it: `Data di arrivo`,
      en: `Arrival date`
    },
    data_partenza: {
      it: `Data di partenza`,
      en: `Departure date`
    },
    prima_data_arrivo: {
      it: `Data di arrivo del primo ospite, in formato YYYY-MM-DD`,
      en: `Arrival date of the first guest, with format YYYY-MM-DD`
    },
    ultima_data_partenza: {
      it: `Data di partenza dell'ultimo ospite, in formato YYYY-MM-DD`,
      en: `Departure date of the last guest, with format YYYY-MM-DD`
    },
    camere_vendute: {
      it: `Numero camere prenotate`,
      en: `Sold rooms`
    },
    ricavo_camere: {
      it: `Ricavo delle sole camere, esclusi extra e pasti`,
      en: `Revenue of the night only, excluding meals and extras`
    },
    ricavo_complessivo: {
      it: `Ricavo della prenotazione, con i pasti ma escludendo gli extra`,
      en: `Revenue of the reservation, with meals but excluding extras`
    },
    data_ricavo_camere: {
      it: `Ricavo del giorno per le sole camere, esclusi extra e pasti`,
      en: `Revenue of the night only for the day, excluding meals and extra`
    },
    data_ricavo_complessivo: {
      it: `Ricavo del giorno, con i pasti ma escludendo gli extra`,
      en: `Revenue of the day, including meals but excluding extras`
    },
    ospiti_adulti: {
      it: `Ospiti maggiorenni`,
      en: `Adult guests`
    },
    ospiti_bambini: {
      it: `Ospiti minorenni, se presenti`,
      en: `Child/teen guests`
    },
    data_ospiti_adulti: {
      it: `Ospiti maggiorenni del giorno`,
      en: `Adult guests of the day`
    },
    data_ospiti_bambini: {
      it: `Ospiti minorenni del giorno, se presenti`,
      en: `Child/teen guests of the day, if present`
    },
    stato: {
      it: `Codice o descrizione dello stato della prenotazione`,
      en: `Code or description of the reservation status`
    },
    tipo_camera: {
      it: `Codice o descrizione della tipologia camera`,
      en: `Code or description of the room type`
    },
    codice_trattamento: {
      it: `Codice o descrizione del trattamento`,
      en: `Code or description of the rate`
    },
    codice_canale: {
      it: `Codice o descrizione del canale di vendita`,
      en: `Code or description of the channel`
    },
    codice_mercato: {
      it: `Codice o descrizione del segmento di mercato`,
      en: `Code or description of the market`
    },
    nazionalità: {
      it: `Nazionalità del prenotante`,
      en: `Nationality of the guest`
    },
    data_cancellazione: {
      it: `Data della disdetta, obbligatorio per prenotazioni cancellate`,
      en: `Cancellation date, mandatory for cancelled reservations`
    },
    cap: {
      it: `Codice postale del prenotante`,
      en: `ZIP code of the guest`
    },
    dettaglio_date: {
      it: `Dettaglio date di soggiorno`,
      en: `Stay dates details`
    },
    data_soggiorno: {
      it: `Data soggiorno, in formato YYYY-MM-DD`,
      en: `Stay date, with format YYYY-MM-DD`
    },
    data: {
      it: `Data, in formato YYYY-MM-DD`,
      en: `Date, with format YYYY-MM-DD`
    },
    codice_prodotto: {
      it: `Codice prodotto`,
      en: `Product code`
    },
    unità_disponibili: {
      it: `Unità disponibili`,
      en: `Available units`
    },
    extra_code: {
      it: `Codice dell'extra`,
      en: `Extra code`
    },
    extra_revenue: {
      it: `Prezzo dell'extra`,
      en: `Extra revenue`
    }
  },
  home: {
    title: {
      it: `Benvenuto!`,
      en: `Welcome!`,
      de: `Willkommen!`
    },
    subtitle: {
      it: `Benvenuto nella documentazione delle API H-benchmark!`,
      en: `Welcome to H-benchmark API documentation!`,
      de: `Willkommen zur Dokumentation der HBenchmark-API!`
    },
    text_1: {
      it: `Hai effettuato l'accesso come: `,
      en: `You are logged in as: `,
      de: `Du bist eingeloggt als: `
    },
    text_2: {
      it: `Qui sono elencate le strutture per cui hai accesso ai dati, e le loro relative credenziali.`,
      en: `Here are listed the properties for which you have data access to, and their credentials.`,
      de: `Hier sind die Eigenschaften aufgeführt, auf die Sie Datenzugriff haben, sowie deren Anmeldeinformationen.`
    },
    text_3: {
      it: `Per avere informazioni su come leggere i dati per una struttura e il suo territorio, cliccare sul link <i>Lettura Dati</i> dal menù a sinistra.`,
      en: `To get informations on how to read data for a property and its territory, click on the link <i>Retrieve Data</i> from the menu on the left.`,
      de: `Klicken Sie links im Menü auf den Link <i>Daten abrufen</i>, um Informationen zum Lesen von Daten für eine Immobilie und ihr Gebiet zu erhalten.`
    },
    text_4: {
      it: `Per avere informazioni su come inviare i dati per una struttura, cliccare sul link <i>Invio Dati</i> dal menù a sinistra.`,
      en: `To get informations on how to send data for a property, click on the link <i>Send Data</i> from the menu on the left.`,
      de: `Um Informationen zum Senden von Daten für eine Eigenschaft zu erhalten, klicken Sie links im Menü auf den Link <i>Daten senden</i>.`
    }
  },
  send_data: {
    text_1: {
      it: `Il sistema HBenchmark riceve quotidianamente informazioni dai gestionali delle strutture aderenti. Tali informazioni vengono rielaborate e salvate in un unico database che le rende consultabili in forma di statistiche anonime e aggregate.`,
      en: `The HBenchmark system receives daily information from the management of the participating structures. This information is processed and saved in a single database which makes it available in the form of anonymous and aggregated statistics.`,
      de: `Das HBenchmark-System erhält täglich Informationen vom Management der beteiligten Strukturen. Diese Informationen werden verarbeitet und in einer einzigen Datenbank gespeichert, die sie in Form von anonymen und aggregierten Statistiken zur Verfügung stellt.`
    },
    text_2: {
      it: `La finalità dell'integrazione con i sistemi gestionali è quella di <b>automatizzare l'estrazione e l'invio delle informazioni su base giornaliera o real time</b>.`,
      en: `The purpose of integration with management systems is to <b>automate the extraction and dispatch of information on a daily or real time basis</b>.`,
      de: `Ziel der Integration in Managementsysteme ist es, das <b>Extrahieren und Senden von Informationen täglich oder in Echtzeit zu automatisieren</b>.`
    },
    text_3: {
      it: `Introduzione`,
      en: `Introduction`,
      de: `Einführung`
    },
    text_4: {
      it: `Le API di HBenchmark sono organizzate secondo lo standard <b>REST</b>. Gli URL per l'utilizzo sono intuitivi e orientati per risorsa, vengono utilizzati i codici di stato <b>HTTP</b> per gli errori e per le varie risposte alle richieste. Vengono utilizzate anche feature HTTP per quanto riguarda l'autenticazione e per i metodi delle richieste compatibili con i vari client HTTP. Tutte le richieste e le risposte delle API saranno in formato <b>JSON</b>, inclusi gli errori.`,
      en: `The HBenchmark APIs are organized according to the <b>REST</b> standard. Usage URLs are intuitive and resource oriented, <b>HTTP</b> status codes are used for errors and for various responses to requests. HTTP features are also used as far as authentication is concerned and for the request methods compatible with the various HTTP clients. All API requests and responses will be in <b>JSON</b> format, including errors.`,
      de: `Die HBenchmark-APIs sind nach dem <b>REST</b>-Standard organisiert. Verwendungs-URLs sind intuitiv und ressourcenorientiert. <b>HTTP</b>-Statuscodes werden für Fehler und für verschiedene Antworten auf Anforderungen verwendet. HTTP-Funktionen werden auch für die Authentifizierung und für die Anforderungsmethoden verwendet, die mit den verschiedenen HTTP-Clients kompatibel sind. Alle API-Anforderungen und -Antworten werden im <b>JSON</b>-Format angezeigt, einschließlich Fehlern.`
    },
    text_5: {
      it: `Autenticazione`,
      en: `Authentication`,
      de: `Authentifizierung`
    },
    text_6: {
      it: `Per poter comunicare con i sistemi di HBenchmark è stata predisposta una HTTP Basic Authentication. Basterà inserire nella richiesta un header HTTP con chiave <b>Authorization</b> e valore il risultato ottenuto da questa procedura:`,
      en: `In order to communicate with the HBenchmark systems, an HTTP Basic Authentication has been set up. Just insert an HTTP header with the key <b>Authorization</b> in the request and value the result obtained by this procedure:`,
      de: `Für die Kommunikation mit den HBenchmark-Systemen wurde eine HTTP-Basisauthentifizierung eingerichtet. Fügen Sie einfach einen HTTP-Header mit dem Schlüssel <b>Authorization</b> in die Anforderung ein und bewerten Sie das durch dieses Verfahren erzielte Ergebnis:`
    },
    text_7: {
      it: `Unire nome utente e password nella stessa stringa "nome_utente:password"`,
      en: `Merge username and password in the same string "username: password"`,
      de: `Führen Sie Benutzername und Passwort in derselben Zeichenfolge "Benutzername: Passwort" zusammen.`
    },
    text_8: {
      it: `Codificare il risultato in Base64`,
      en: `Encode the result in Base64`,
      de: `Codieren Sie das Ergebnis in Base64`
    },
    text_9: {
      it: `Prependere la stringa "Basic " (con spazio finale) al risultato codficato in Base64`,
      en: `Prepend the string "Basic" (with trailing space) for the result encoded in Base64`,
      de: `Bereiten Sie die Zeichenfolge "Basic" (mit nachfolgendem Leerzeichen) für das in Base64 codierte Ergebnis vor`
    },
    text_10: {
      it: `I dati di nome utente e password sono per singola struttura, e sono reperibili nella schermata principale (cliccando il tasto <i>Home</i> dal menù a sinstra).`,
      en: `The username and password data are for each structure, and can be found on the main screen (by clicking the <i>Home</i> button from the menu on the left).`,
      de: `Die Benutzernamen- und Kennwortdaten gelten für jede Struktur und können auf dem Hauptbildschirm angezeigt werden (indem Sie im Menü links auf die Schaltfläche <i>Home</i> klicken).`
    },
    text_11: {
      it: `Esempio: una struttura ha nome utente: <pre class="inline">HBENCH_00777</pre> e password <pre class="inline">abcdefg</pre>`,
      en: `Example: a property has username: <pre class="inline">HBENCH_00777</pre> and password <pre class="inline">abcdefg</pre>`,
      de: `Beispiel: Eine Struktur hat den Benutzernamen <pre class="inline">HBENCH_00777</pre> und das Passwort <pre class="inline">abcdefg</pre>`
    },
    text_12: {
      it: `Il valore dell'header <b>Authorization</b> finale sarà: <pre class="inline">SEJFTkNIXzAwNzc3OmFiY2RlZmc=</pre>`,
      en: `The value of the final <b>Authorization</b> header will be: <pre class="inline">SEJFTkNIXzAwNzc3OmFiY2RlZmc=</pre>`,
      de: `Der Wert des endgültigen <b>Authorization</b>-Headers lautet: <pre class="inline">SEJFTkNIXzAwNzc3OmFiY2RlZmc=</pre>`
    },
    text_13: {
      it: `Frequenza invio dati`,
      en: `Send data frequency`,
      de: `Häufigkeit der Datenübertragung`
    },
    text_14: {
      it: `Ogni trasmissione deve contenere lo storico degli ultimi 15 giorni di prenotazioni e il previsionale dei prossimi 365 giorni di prenotazioni.`,
      en: `Every trasmission must contain historical data of the last 15 days of reservations and on the book data of the next 365 days of reservations.`
    },
    text_15: {
      it: `Solo la <b>prima trasmissione</b> deve contenere lo storico degli ultimi 2 anni (o fin quando possibile) di prenotazioni.`,
      en: `Only the <b>first trasmission</b> msut contain historical data of the last 2 years (or until available) of reservations.`
    },
    text_16: {
      it: `Per effettuare il filtro sulle date di prenotazione (ad esempio gli ultimi 15 giorni) basarsi sempre sulla <b>data di partenza</b>, ovvero: le prenotazioni degli ultimi 15 giorni saranno quelle che hanno come data di partenza una data strettamente maggiore di 16 giorni fa.`,
      en: `To filter the reservations range (i.e. last 15 days) always use the <b>departure date</b>, which means: the last 15 days of reservations will be the ones that have departure date strictly greater than 16 days ago.`
    },
    text_17: {
      it: `Ad eccezione della prima trasmissione, è possibile implementare l'invio delle prenotazioni anche in modalità delta, quindi senza reinviare la totalità delle prenotazioni presenti nell'intervallo di tempo -15 / +365. Si potranno quindi inviare solo le nuove prenotazioni, o quelle che hanno subito modifiche sostanziali, rispetto all'ultimo invio effettuato. <b>Attenzione</b>: qualora si implementi la modalità DELTA, è necessario assicurarsi che, se per qualsiasi motivo la trasmissione non andasse a buon fine, le prenotazioni vengano reinviate al tentativo successivo, e non vadano perse.`,
      en: `With the exception of the first trasmission, it is possible to develop the sending mechanism in <b>delta</b> mode, thus avoiding to send the whole reservations present on the range -15 / +365. It will be possible to send only new reservations, or the ones which have undergone substantial changes, with respect to the last trasmission. <b>Warning</b>: if you decide to send data in DELTA mode, you must be sure that, if by any means the trasmission does not end successfully, the reservations will be marked for a future send, and will not be lost.`
    },
    text_18: {
      it: `Endpoint`,
      en: `Endpoint`
    },
    text_19: {
      it: `L'endpoint per l'invio dati è <a>https://api.hbenchmark.it/transmissions</a>.`,
      en: `The endpoint for data sending is <a>https://api.hbenchmark.it/transmissions</a>.`
    },
    text_22: {
      it: `Tutti gli endpoint vanno sempre invocati con il metodo <b>POST</b>.`,
      en: `All endpoints must be called with method <b>POST</b>`
    },
    text_23: {
      it: `Contenuto`,
      en: `Content`
    },
    text_24: {
      it: `Il contenuto dell'invio dati va passato come <b>body</b> della richiesta. Esso è sempre un <b>array</b> di oggetti, che rappresentano prenotazioni. Anche quando non ci sono prenotazioni da inviare, va comunque mandato un array, che in questo caso sarà vuoto.`,
      en: `The content must be sent as <b>body</b> of the request. The body is always an <b>array</b> of objects, which represent the reservations. When you have no reservations to send, you still must send an empty array.`
    },
    text_25: {
      it: `Un oggetto prenotazione dell'array è rappresentato come segue:`,
      en: `An object inside the reservation array is structured as follows:`
    },
    text_26: {
      it: `Nel caso in cui ci siano prenotazioni più complesse, come per esempio prenotazioni con cambio di camera, ospiti che si aggiungono per una parte del soggiorno, tariffe effettuate su base giornaliera, ecc., è possibile inviare i dati con un oggetto avanzato, dove a livello principale sono riportati i campi validi per la prenotazione, mentre nel campo <b>stay_dates</b> vengono specificati tutti i dettagli. L'oggetto avanzato si compone come segue:`,
      en: `If you have more complex reservations, such as the possibility to switch room type during the stay, or guests that arrive/leave only for a part of the stay, or rates by day, etc. you can send data using an advanced object, where at reservation level there are only the fields valid for the reservation as a whole, and on the field <b>stay_dates</b> you can specify all the details of every date of the stay. The advanced object is structured as follows:`
    }
  },
  send_inventory: {
    text_1: {
      it: `Il sistema HBenchmark riceve dati di disponibilità per calcolare correttamente l'occupazione e le chiusure. Questi dati sono integrativi a quelli inviati nella scheda <b>Invio Dati</b>.`,
      en: `The HBenchmark system receives inventory data in order to calculate correctly occupation rates and closures. This information is additional to the one send wih the <b>Send Data</b> page.`
    },
    text_2: {
      it: `Autenticazione`,
      en: `Authentication`,
      de: `Authentifizierung`
    },
    text_3: {
      it: `L'autenticazione è identica a quanto riportato nella scheda <b>Invio Dati</b>.`,
      en: `Authentication is identical to the one described in the page <b>Send Data</b>.`
    },
    text_4: {
      it: `Frequenza invio dati`,
      en: `Send data frequency`,
      de: `Häufigkeit der Datenübertragung`
    },
    text_5: {
      it: `Ogni trasmissione deve contenere gli ultimi 90 giorni di disponibilità e i prossimi 365 giorni di disponibilità.`,
      en: `Every trasmission must contain data of the last 90 days of inventory and data of the next 365 days of inventory.`
    },
    text_6: {
      it: `Endpoint`,
      en: `Endpoint`
    },
    text_7: {
      it: `L'endpoint per l'invio dati è <a>https://api.hbenchmark.it/transmissions</a>.`,
      en: `The endpoint for data sending is <a>https://api.hbenchmark.it/transmissions</a>.`
    },
    text_8: {
      it: `Tutti gli endpoint vanno sempre invocati con il metodo <b>POST</b>.`,
      en: `All endpoints must be called with method <b>POST</b>`
    },
    text_9: {
      it: `Contenuto`,
      en: `Content`
    },
    text_10: {
      it: `Il contenuto dell'invio dati va passato come <b>body</b> della richiesta. Esso è sempre un <b>array</b> di oggetti, che rappresentano disponibilità. Anche quando non ci sono disponibilità da inviare, va comunque mandato un array, che in questo caso sarà vuoto.`,
      en: `The content must be sent as <b>body</b> of the request. The body is always an <b>array</b> of objects, which represent the inventory. When you have no inventory to send, you still must send an empty array.`
    },
    text_11: {
      it: `Un oggetto disponibilità dell'array è rappresentato come segue:`,
      en: `An object inside the inventory array is structured as follows:`
    }
  },
  retrieve_data: {
    text_1: {
      it: `La lettura dei dati si basa su autenticazione a livello di utente. L'utente con cui si è effettuato l'accesso è abilitato alla lettura dati per una o più strutture. Le si possono controllare nella pagina principale.`
    },
    text_2: {
      it: `Autenticazione`,
      en: `Authentication`,
      de: `Authentifizierung`
    },
    text_3: {
      it: `Per autenticarsi è necessario effettuare una chiamata <b>POST</b> al seguente endpoint: <a>https://backend.hbenchmark.it/anagrafe/autentica</a>, come segue:`
    },
    text_4: {
      it: `In caso di credenziali valide la risposta sarà del tipo:`
    },
    text_5: {
      it: `Il contenuto del campo <b>gettone</b> sarà il token da utilizzare per autenticare tute le successive chiamate, e andrà passato come header <b>Authorization</b>, preponendo la stringa "Bearer ". Il gettone ha una durata di 24 ore, terminate le quali andrà richiesto con la medesima procedura, per poter continuare ad essere autenticati.`
    },
    text_6: {
      it: `Lettura Dati`
    },
    text_7: {
      it: `I dati vengono letti utilizzando l'endpoint <a>https://backend.hbenchmark.it/portale/dati/statistiche/nuova</a>. Per interrogare una nuova statistica effettuare una chiamata come segue:`
    },
    text_8: {
      it: `Di seguito verranno spiegati nel dettaglio tutti i campi.`
    },
    text_9: {
      it: `La granularità è il dettaglio temporale con cui vengono restituiti i dati. Può essere: <pre class="inline">giornaliera</pre> per avere i dati del singolo giorno, <pre class="inline">settimanale</pre> per aggregare a livello settimanale, <pre class="inline">mensile</pre> per aggregare a livello mensile, <pre class="inline">annuale</pre> per aggregare a livello annuale, oppure <pre class="inline">totale</pre>, per aggregare sulla totalità della cornice temporale.`
    },
    text_10: {
      it: `La cornice temporale delimita gli estremi temporali per cui recuperare i dati. Può essere fornita con i campi <pre class="inline">da</pre> e <pre class="inline">a</pre>, come segue:`
    },
    text_11: {
      it: `Oppure, per evitare di dover aggiornare le date ad ogni chiamata, si può passare un codice che determina un intervallo fisso di giorni a partire dalla data attuale. I codici possibili sono:`
    },
    text_12: {
      it: `I gruppi rappresentano un'insieme di una o più strutture per cui ottenere i dati. Per sapere tutti i gruppi a cui si ha accesso, effettuare una chiamata all'endpoint <a>https://backend.hbenchmark.it/portale/dati/gruppi</a> come segue:`
    },
    text_13: {
      it: `La risposta sarà un array di oggetti, ognuno identificato da un campo <b>_id</b> e un campo <b>tipo</b>. Di seguito un esempio di risposta:`
    },
    text_14: {
      it: `I gruppi possono essere di queste tipologie:`
    },
    text_15: {
      it: `Quando si vuole ottenere i dati per un determinato gruppo, è necessario passarlo all'interno dell'array <b>gruppi</b> come oggetto con i campi <b>_id</b> e <b>tipo</b> così come forniti dall'endpoint <a>https://backend.hbenchmark.it/portale/dati/gruppi</a>.`
    },
    text_16: {
      it: `Gli indicatori rappresentano il tipo di dato che si vuole ottenere. Alcuni esempi sono: occupazione, ricavo, adr, revpar, ecc. Per sapere quali tipi di indicatori sono disponibili, effettuare una chiamata all'endpoint <a>https://backend.hbenchmark.it/portale/dati/indicatori</a> come segue:`
    },
    text_17: {
      it: `Il <b>percorso segmentazione</b> rappresenta la segmentazione per cui è possibile calcolare un indicatore. Inizialmente questo valore va passato come array vuoto, e l'endpoint risponderà con una lista di segmenti. Di seguito un esempio:`
    },
    text_18: {
      it: `A questo punto si potrà inserire il <b>codice</b> di ogni segmento dentro il <b>percorso segmentazione</b> della richiesta, ed esplorare così tutti i segmenti aggiuntivi. Esempio:`
    },
    text_19: {
      it: `Ottenendo la risposta:`
    },
    text_20: {
      it: `Ad un certo punto si arriverà ad ottenere nella risposta degli oggetti di tipo <b>indicatore</b> (e non più segmento). Questi sarà possibile utilizzarli come indicatori da richiedere nella statistica, passando il loro <b>codice</b> e la loro <b>segmentazione</b>. Ad esempio, effettuando una chiamata agli indicatori con il <b>percorso segmentazione</b> uguale a <pre class="inline">[ "occupazione", "totale" ]</pre>, si avrà la seguente risposta:`
    },
    text_21: {
      it: `A questo punto si hanno tutte le informazioni per comporre una richiesta di statistica. Supponiamo quindi che si voglia chiedere l'indicatore <pre class="inline">occupazione</pre>, segmentato per <pre class="inline">totale</pre>, per il gruppo formato dalla singola struttura <b>Hotel Ghiaccio</b>, per i prossimi 30 giorni, e granularizzato giornalmente. Ecco la richiesta completa:`
    },
    text_22: {
      it: `La risposta sarà come segue:`
    },
    text_23: {
      it: `Come si vede, la statistica fornisce un campo <b>serie</b>, che contiene tutte le serie dati ottenute per i gruppi, indicatori e cornici temporali passate. Ogni serie contiene un <b>codice_periodo</b>, <b>codice_gruppo</b> e <b>codice_indicatore</b> per poterla collegare ad un periodo, gruppo e indicatore.`
    },
    text_24: {
      it: `La serie poi contiene il campo <b>dati</b> che rappresenta i valori dell'indicatore granularizzati secondo la granularità passata.`
    }
  }
}

export { dizionario_documentazione }
